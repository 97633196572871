exports.components = {
  "component---src-pages-3-d-modeling-js": () => import("./../../../src/pages/3d_modeling.js" /* webpackChunkName: "component---src-pages-3-d-modeling-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-me-js": () => import("./../../../src/pages/about_me.js" /* webpackChunkName: "component---src-pages-about-me-js" */),
  "component---src-pages-coding-tutorials-js": () => import("./../../../src/pages/coding_tutorials.js" /* webpackChunkName: "component---src-pages-coding-tutorials-js" */),
  "component---src-pages-development-skills-js": () => import("./../../../src/pages/development_skills.js" /* webpackChunkName: "component---src-pages-development-skills-js" */),
  "component---src-pages-digital-cv-js": () => import("./../../../src/pages/digital_cv.js" /* webpackChunkName: "component---src-pages-digital-cv-js" */),
  "component---src-pages-game-dev-js": () => import("./../../../src/pages/game_dev.js" /* webpackChunkName: "component---src-pages-game-dev-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-local-pub-js": () => import("./../../../src/pages/local_pub.js" /* webpackChunkName: "component---src-pages-local-pub-js" */),
  "component---src-pages-watch-js": () => import("./../../../src/pages/watch.js" /* webpackChunkName: "component---src-pages-watch-js" */)
}

